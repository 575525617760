@import url('https://fonts.googleapis.com/css2?family=Noto+Serif:ital,wght@0,100..900;1,100..900&display=swap');

html {
  background-color: #f0eee5;
  font-family: 'Noto Serif';
  height: 100vh;
  width: 100vw;
}

.navbar {
  border-bottom: 1px solid gray;
  display: flex;
  background-color: #fbf0e0;
}

.navbar button {
  margin: 0 auto;
  background-color: transparent;
  border: none;
}

.navbar img {
  margin: 0 auto 10px auto;
  max-height: 75px;
  transition: transform 0.2s ease-in-out, filter 0.5s ease-in-out;
}

.navbar img:hover {
  transform: scale(1);
  filter: drop-shadow(0 0 0.2rem);
}
@keyframes genieEffect {
  0% {
    transform: scaleX(0) scaleY(0);
    opacity: 0;
  }
  50% {
    transform: scaleX(0.5) scaleY(0.6);
    opacity: 0.5;
  }
  100% {
    transform: scaleX(1) scaleY(1);
    opacity: 1;
  }
}

.tooltip {
  display: flex;
  margin: 10px auto 0px auto;
  animation: genieEffect 0.5s cubic-bezier(0.1, 0.6, 0.7, 1.5) both;
}

ul.tooltip-menu {
  padding-left: 0;
}

.tooltip .tooltip-menu {
  list-style-type: none;
  max-width: 300px;
  margin: 0 auto;
}

.tooltip .tooltip-menu li {
  border-right: 1px solid black;
  border-left: 1px solid black;
  border-bottom: 1px solid black;
  background-color: #ebe9de;
  text-align: center;
  width: 150px;
}

.tooltip .tooltip-menu li:first-child {
  border-top: 1px solid black;
  border-top-left-radius: 7px;
  border-top-right-radius: 7px;
}

.tooltip .tooltip-menu li:last-child {
  border-bottom-left-radius: 7px;
  border-bottom-right-radius: 7px;
}

.tooltip li:hover {
  background-color: rgb(197, 197, 197);
}


.content {
  display: flex;
  flex-direction: column;
  max-width: 265px;
  margin: 0 auto;
}

.content div {
  margin: 0 auto;

}

.content button {
  width: 15vw;
  margin-top: 2vh;
}

.content audio {
  margin: 0 auto;
}

.content .voice {
  margin: 0px auto 0px 0px;
}